import React from 'react'

import { block, groupList, toggle, upload } from '../../fields'
import { id, ImgDiv, placeholder } from '../../utilities'

import styles from './stackedImages.module.scss'

const generateItem = () => ({
  id: id(),
  image: placeholder(),
  disabled: false
})

export const StackedImagesSettings = images => block('Stacked Images', [
  groupList('Items', [
    upload(images),
    toggle('Disabled')
  ], item => ({
    key: item.id,
    label: 'Image'
  }))
], {
  items: [generateItem(), generateItem()]
})

export const StackedImages = ({ block: { items } }) => {
  const images = items.filter(item => !item.disabled)

  return (
    <section className={styles.section}>
      {images[0] && images[0].image && (
        <ImgDiv className={styles.backgroundImage} image={images[0].image} />
      )}
      {images[1] && images[1].image && (
        <ImgDiv className={styles.foregroundImage} image={images[1].image} />
      )}
    </section>
  )
}
