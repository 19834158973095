import camelCase from 'camelcase'

export const upload = (
  images,
  label: string = 'Image',
  subdirectory: string = '',
  name: string = camelCase(label),
  description: string = '',
  rawJson: boolean = false
) => ({
  component: 'image',
  label,
  name: rawJson ? `rawJson.${name}` : name,
  description,
  parse: (filename: string) => filename ? `../images/${subdirectory ? subdirectory + '/' : ''}${filename}` : '../images/placeholders/placeholder-clear.png',
  uploadDir: () => `/content/images/${subdirectory ? subdirectory + '/' : ''}`,
  previewSrc: (formValues, fieldProps) => {
    const image = images && images.length && fieldProps.input && fieldProps.input.value ? images.find(asset => asset && fieldProps.input.value.includes(asset.relativePath)) : ''
    if (!image) return ''
    return image.publicURL
  }
})
