import camelCase from 'camelcase'

export const blocks = (
  label: string,
  templates: any = {},
  name: string = camelCase(label),
  description: string = '',
  rawJson: boolean = false
) => ({
  component: 'blocks',
  label,
  name: rawJson ? `rawJson.${name}` : name,
  description,
  templates
})
