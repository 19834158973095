import camelCase from 'camelcase'
import { toggle } from './toggle'

export const block = (
  label: string,
  fields: any[] = [],
  defaults: object = {},
  key: string = camelCase(label)
) => ({
  label,
  key,
  fields: [
    ...fields,
    toggle('Disabled')
  ],
  defaultItem: () => ({
    id: Math.random().toString(36).substr(2, 9),
    ...defaults,
    disabled: false
  })
})
