import React from 'react'
import BackgroundImage from 'gatsby-background-image'

interface props {
  image: any,
  className: string,
  children?: React.ReactNode
}

export const ImgDiv = ({ image, className, children }: props) => image ? image.childImageSharp && image.childImageSharp.fluid ? (
  <BackgroundImage className={className} Tag='div' fluid={image.childImageSharp.fluid}>
    {children}
  </BackgroundImage>
) : (
  <div className={className} style={{ backgroundImage: `url("${image.publicURL}")` }}>
    {children}
  </div>
) : (
  <div className={className}>
    {children}
  </div>
)
