import React from 'react'
import { graphql } from 'gatsby'
import { useLocalJsonForm } from 'gatsby-tinacms-json'

import { colorOptions } from '../theme'
import { Layout } from '../components'
import { id, lipsum } from '../utilities'
import { blocks, group, groupList, select, text, textarea, toggle } from '../fields'
import { ContactForm, ContactFormSettings, Hero, HeroSettings, Images, ImagesSettings, ImageBar, ImageBarSettings, KnowledgeBase, KnowledgeBaseSettings, Map, MapSettings, RichText, RichTextSettings, Slider, SliderSettings, StackedImages, StackedImagesSettings, TabbedImages, TabbedImagesSettings, Testimonial, TestimonialSettings } from '../blocks'

import styles from './page.module.scss'
import slugify from 'slugify'

const Page = ({ data: { pagesJson, allFile: { nodes: images } } }) => {
  const colorDefaults = () => ({
    background: 'default',
    text: 'default'
  })
  const generateColumn = () => ({
    id: id(),
    blocks: [],
    colors: colorDefaults(),
    disabled: false
  })

  const generateRow = () => ({
    id: id(),
    columns: [generateColumn()],
    colors: colorDefaults(),
    disabled: false
  })

  const [{ title, description, colors, rows, disableFooter }] = useLocalJsonForm(pagesJson, {
    id: id(),
    label: 'Page Options',
    initialValues: () => {
      const title = lipsum.generateWords(2)
      return ({
        title: title,
        slug: slugify(title),
        description: lipsum.generateSentences(3),
        colors: colorDefaults(),
        rows: [generateRow()]
      })
    },
    fields: [
      text('Title', 'title', 'The page title is used for the tab/window title, SEO title and initial page slug.', 'Page Title', true, true),
      text('Slug', 'slug', 'Serves as part of the web address to this page. Initially set to a slugged version of the page title.', 'page-title', true, true),
      textarea('Description', 'description', 'Describe this page in ~155 characters.', true, true),
      group('Colors', [
        select('Background', colorOptions),
        select('Text', colorOptions)
      ], 'colors', 'Custom default colors for the page.', true),
      groupList('Rows', [
        group('Colors', [
          select('Background', colorOptions),
          select('Text', colorOptions)
        ],
        'colors', 'Custom default colors for the row.'),
        groupList('Columns', [
          group('Colors', [
            select('Background', colorOptions),
            select('Text', colorOptions)
          ],
          'colors', 'Custom default colors for the column.'),
          blocks('Blocks', {
            'Contact Form': ContactFormSettings(),
            Hero: HeroSettings(images),
            Image: ImagesSettings(images),
            'Image Bar': ImageBarSettings(images),
            'Knowledge Base': KnowledgeBaseSettings(),
            Map: MapSettings(),
            'Rich Text': RichTextSettings(),
            Slider: SliderSettings(),
            'Stacked Images': StackedImagesSettings(images),
            'Tabbed Images': TabbedImagesSettings(images),
            Testimonial: TestimonialSettings(images)
          })
        ], item => {
          const label = []
          item.blocks && item.blocks.length ? item.blocks.forEach(block => label.push(block._template)) : label.push('Empty')
          return ({
            key: item.id,
            label: label.join(', ') + ' Column'
          })
        }, () => generateColumn(), 'columns', 'Organizable vertical containers.')
      ], item => {
        const label = []
        item.columns && item.columns.length ? item.columns.forEach(column => column.blocks && column.blocks.length ? column.blocks.length === 1 ? label.push(column.blocks[0]._template) : label.push(`${column.blocks.length} Blocks`) : label.push('Empty Column')) : label.push('Empty')
        return ({
          key: item.id,
          label: label.join(' / ') + ' Row'
        })
      },
      () => generateRow(),
      'rows',
      'Organizable horizontal containers.', true),
      toggle('Disable Footer', 'disableFooter', '', true)
    ]
  })

  return (
    <Layout
      title={title}
      description={description}
      disableFooter={disableFooter}
      textColor={(colors && colors.text && `var(${colors.text})`) || 'black'}
      backgroundColor={(colors && colors.background && `var(${colors.background})`) || 'white'}
    >
      {rows && rows.map((row, i) => (
        <section
          key={i}
          className={styles.row}
          style={{
            color: (row.colors && row.colors.text && row.colors.text !== 'default' && `var(${row.colors.text})`) || 'inherit',
            backgroundColor: (row.colors && row.colors.background && row.colors.background !== 'default' && `var(${row.colors.background})`) || 'inherit'
          }}
        >
          {row.columns && row.columns.map((column, i) => (
            <section
              key={i}
              className={styles.column}
              style={{
                color: (column.colors && column.colors.text && column.colors.text !== 'default' && `var(${column.colors.text})`) || 'inherit',
                backgroundColor: (column.colors && column.colors.background && column.colors.background !== 'default' && `var(${column.colors.background})`) || 'inherit'
              }}
            >
              {column.blocks && column.blocks.filter(block => !block.disabled).map((block, i) => {
                if (block._template === 'Contact Form') return <ContactForm key={i} block={block} />
                if (block._template === 'Hero') return <Hero key={i} block={block} />
                if (block._template === 'Images') return <Images key={i} block={block} />
                if (block._template === 'Image Bar') return <ImageBar key={i} block={block} />
                if (block._template === 'Knowledge Base') return <KnowledgeBase key={i} block={block} />
                if (block._template === 'Map') return <Map key={i} block={block} />
                if (block._template === 'Rich Text') return <RichText key={i} block={block} />
                if (block._template === 'Slider') return <Slider key={i} block={block} />
                if (block._template === 'Stacked Images') return <StackedImages key={i} block={block} />
                if (block._template === 'Tabbed Images') return <TabbedImages key={i} block={block} />
                if (block._template === 'Testimonial') return <Testimonial key={i} block={block} />
                return <p key={i}>{block._template} not recognized</p>
              })}
            </section>
          ))}
        </section>
      ))}
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    pagesJson(slug: {eq: $slug }) {
      slug
      title
      description
      colors {
        background
        text
      }
      disableFooter
      rows {
        id
        colors {
          background
          text
        }
        columns {
          id
          colors {
            background
            text
          }
          blocks {
            _template
            title
            subtitle
            nameLabel
            methodLabel
            buttonLabel
            categories {
              id
              category
              title
              disabled
              items {
                id
                title
                description
                text
                disabled
                image
              }
            }
            action
            toc
            items {
              id
              disabled
              heading
              label
              url
              body
              pageBlocks {
                _template
                # color
                disabled
                id
                # style
                # text
                # title
                # videoLink
              }
              image {
                publicURL
                relativePath
                childImageSharp {
                  fluid(quality: 100) {
                    src
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            options {
              showScrollButton
              showPauseButton
              autoCyclePages
              autoCycleDelay
              clickCycleDelay
            }
            disabled
            author
            quote
            id
          }
        }
      }
      rawJson
      fileRelativePath
    }
    allFile {
      nodes {
        publicURL
        relativePath
      }
    }
  }
`
