import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { block, group, groupList, number, textarea, toggle, upload } from '../../fields'
import { id, ImgDiv, lipsum, placeholder } from '../../utilities'

import styles from './hero.module.scss'

const generatePage = () => ({
  id: id(),
  heading: lipsum.generateSentences(1),
  body: lipsum.generateSentences(2),
  image: placeholder(),
  disabled: false
})

const generateBlock = () => ({
  items: [generatePage(), generatePage()],
  options: {
    showScrollButton: true,
    autoCyclePages: true,
    autoCycleDelay: 10,
    showPauseButton: true,
    clickCycleDelay: 20
  }
})

export const HeroSettings = images => block('Hero', [
  group('Options', [
    toggle('Show Scroll Button'),
    toggle('Show Pause Button'),
    toggle('Auto Cycle Pages'),
    number('Auto Cycle Delay'),
    number('Click Cycle Delay')
  ]),
  groupList('Items', [
    textarea('Heading'),
    textarea('Body'),
    upload(images),
    toggle('Disabled')
  ], item => ({
    key: item.id,
    label: item.heading
  }), generatePage())
], generateBlock())

export const Hero = ({ block: { items, options } }) => {
  console.log(items)
  const { settingsJson: { assets: { assets } } } = useStaticQuery(graphql`
  {
    settingsJson(pk: {eq: "settings"}) {
      rawJson
      fileRelativePath
      assets {
        assets {
          name
          asset {
            publicURL
          }
        }
      }
    }
  }
  `)

  const [slides, setSlides] = useState([])
  const [active, setActive] = useState(undefined)
  const [clicked, setClicked] = useState(false)
  const [timer, setTimer] = useState(undefined)
  const [paused, setPaused] = useState(false)

  useEffect(() => {
    setSlides(items.filter(item => !item.disabled))
    setActive(0)
  }, [items])

  const cycle = () => {
    if (slides.length > 1) {
      active < slides.length - 1 ? setActive(active + 1) : setActive(0)
    }
  }

  useEffect(() => {
    if (options.autoCyclePages) {
      clearTimeout(timer)
      setPaused(false)
      if (clicked) {
        setClicked(false)
        setTimer(setTimeout(cycle, (options.clickCycleDelay * 1000) || 20000))
      } else {
        setTimer(setTimeout(cycle, (options.autoCycleDelay * 1000) || 5000))
      }
    }
  }, [options, active])

  const togglePause = () => {
    if (!paused) {
      setPaused(true)
      clearTimeout(timer)
    } else {
      setPaused(false)
      setTimer(setTimeout(cycle, (options && options.autoCycleDelay * 1000) || 5000))
    }
  }

  const Contents = ({ page }) => {
    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          {page.heading && page.heading.split('\n').map((line, i) => {
            return (
              <h1 key={i}>{line}</h1>
            )
          })}
        </div>
        <div className={styles.body}>
          {page.body && page.body.split('\n').map((line, i) => (
            <h2 key={i}>{line}</h2>
          ))}
        </div>
        {slides.length > 1 && (
          <div className={styles.controls}>
            {options.showPauseButton && (
              <div className={styles.pause + `${paused ? ' ' + styles.paused : ''}`} onClick={togglePause}>
                <span className={styles.line} />
                <span className={styles.line} />
              </div>
            )}
            {slides.map((page, i) => {
              const setActiveSlide = () => {
                setActive(i)
                setClicked(true)
              }
              return (
                <div style={{ gridColumn: ((5 - slides.length)) + i }} className={styles.button + `${i === active ? ' ' + styles.active : ''}`} key={i} onClick={setActiveSlide}>
                  <h1 className={styles.index}>{`0${i + 1}`}</h1>
                  {page.heading && page.heading.split('\n').map((line, i) => {
                    return (
                      <h1 key={i}>{line}</h1>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  const chevronDown = assets.find(asset => asset.name === 'Chevron Down').asset.publicURL

  return (
    <section className={styles.section}>
      {slides.map((page, i) => {
        return (
          <div key={page.id} className={styles.pageContainer + `${i === active ? ' ' + styles.active : ''}`}>
            <ImgDiv className={styles.page} image={page.image}>
              <Contents page={page} />
            </ImgDiv>
          </div>
        )
      })}
      {options.showScrollButton && (
        <div className={styles.scrollContainer}>
          <div className={styles.scrollButton}>
            {chevronDown && (
              <img className={styles.icon} src={chevronDown} alt='scroll down' />
            )}
          </div>
        </div>
      )}
    </section>
  )
}
