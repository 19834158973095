import React from 'react'
import ReactPlayer from 'react-player'

import { colorOptions } from '../../theme'
import { block, blocks, groupList, select, text, textarea } from '../../fields'
import { id, lipsum, randomVideo } from '../../utilities'

import styles from './richText.module.scss'

const generateButton = () => ({
  id: id(),
  disabled: false,
  buttonLabel: lipsum.generateWords(2),
  _template: 'button'
})

const generateParagraph = () => ({
  id: id(),
  text: lipsum.generateSentences(4),
  style: 'Regular',
  color: 'default',
  disabled: false,
  _template: 'paragraph'
})

const generateRow = () => ({
  id: id(),
  pageBlocks: [],
  disabled: false
})

const generateVideo = () => ({
  id: id(),
  videoLink: randomVideo(),
  title: lipsum.generateWords(6),
  disabled: false,
  _template: 'video'
})

export const RichTextSettings = () => block('Rich Text', [
  text('Title'),
  groupList('Items', [
    blocks('Page Blocks', {
      paragraph: block('Paragraph', [
        textarea('Text'),
        select('Style', ['Regular', 'Bold', 'Large']),
        select('Color', colorOptions)
      ], generateParagraph()),
      video: block('Video', [
        text('Video Link'),
        text('Title')
      ], generateVideo()),
      button: block('Button', [
        text('Button Label')
      ], generateButton())
    })
  ], item => ({
    key: item.id,
    label: item.pageBlocks.map(block => block._template).join(', ') + ' block'
  }))
], {
  title: lipsum.generateWords(4),
  items: [generateRow()]
})

export const RichText = ({ block: { title, items } }) => {
  const gridTemplateRows = () => {
    // if (block.title && block.richText) return 'auto 1fr'
    return 'unset'
  }
  return (
    <section className={styles.section} style={{ gridTemplateRows: gridTemplateRows() }}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {!items || items.length === 0 ? (
        // No Content
        <div>Rich Text</div>
      ) : items.length === 1 ? (
        // Full Block
        items.map(({ pageBlocks }, i) => {
          return (
            <div key={i}>
              {pageBlocks && pageBlocks.map((block, i) => {
                return (
                  <div key={i}>
                    {block._template === 'paragraph' && block.style === 'Regular' && (
                      <p>{block.text}</p>
                    )}
                    {block._template === 'paragraph' && block.style === 'Bold' && (
                      <p><strong>{block.text}</strong></p>
                    )}
                    {block._template === 'paragraph' && block.style === 'Large' && (
                      <h2>{block.text}</h2>
                    )}
                    {block._template === 'video' && block.videoLink && (
                      <ReactPlayer url={block.videoLink} />
                    )}
                  </div>
                )
              })}
            </div>
          )
        })
      ) : items.length === 2 ? (
        // Columns
        <p>Columns</p>
      ) : (
        // Tabs
        <p>Tabs</p>
      )}
    </section>
  )
}
