import React from 'react'

import { block, text } from '../../fields'
import { lipsum } from '../../utilities'

import styles from './contactForm.module.scss'

export const ContactFormSettings = () => block('Contact Form', [
  text('Title'),
  text('Subtitle'),
  text('Name Label'),
  text('Method Label'),
  text('Button Label'),
  text('TOC'),
  text('Pageclip URL', 'action')
], {
  title: lipsum.generateWords(4),
  subtitle: lipsum.generateWords(8),
  nameLabel: lipsum.generateWords(2),
  methodLabel: lipsum.generateWords(2),
  buttonLabel: lipsum.generateWords(3),
  toc: lipsum.generateWords(7),
  action: 'https://send.pageclip.co/Od5O1Ov6E3apA4SrUgdyzObHzMdu5VBK'
})

export const ContactForm = ({ block: { action, buttonLabel, methodLabel, nameLabel, subtitle, title, toc } }) => {
  return (
    <section className={styles.section}>
      <div className={styles.contactForm}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
        <form className={styles.form + ' pageclip-form'} action={action} method='post'>
          <fieldset className={styles.inputFieldset}>
            <label className={styles.nameLabel}>{nameLabel}:</label>
            <input className={styles.name} type='text' name='name' id='name' />
          </fieldset>
          <fieldset className={styles.inputFieldset}>
            <label className={styles.methodLabel}>{methodLabel}:</label>
            <div className={styles.methodContainer}>
              <div className={styles.methodDropdown} />
              <input className={styles.method} type='text' name='method' id='method' />
            </div>
          </fieldset>
          <fieldset className={styles.checkboxFieldset}>
            <input type='checkbox' name='tc' id='tc' />
            <label htmlFor='tc'>{toc}</label>
          </fieldset>
          <button type='submit' className={styles.button + ' pageclip-form__submit'}>{buttonLabel}</button>
        </form>
      </div>
    </section>
  )
}
