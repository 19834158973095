import camelCase from 'camelcase'

export const text = (
  label: string,
  name: string = camelCase(label),
  description: string = '',
  placeholder: string = '',
  required: boolean = false,
  rawJson: boolean = false
) => ({
  component: 'text',
  label,
  name: rawJson ? `rawJson.${name}` : name,
  description,
  parse: value => value || '',
  placeholder,
  required
})
