import React, { useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'
import { graphql, useStaticQuery } from 'gatsby'

import { block, text } from '../../fields'
import { lipsum } from '../../utilities'

import styles from './map.module.scss'

export const MapSettings = () => block('Map', [
  text('Title')
], {
  title: lipsum.generateWords(4)
})

export const Map = ({ block }) => {
  // const { site: { siteMetadata: { mapboxToken } } } = useStaticQuery(graphql`{
  //   site {
  //     siteMetadata {
  //       mapboxToken
  //     }
  //   }
  // }`)

  // const locations = [{
  //   name: 'OSU Tulsa - Eastgate',
  //   lng: -95.8215315,
  //   lat: 36.1316741
  // }, {
  //   name: 'OSU Tulsa',
  //   lng: -95.9900506,
  //   lat: 36.1636422
  // }]

  // const [view, setView] = useState(null)
  // const mapContainer = useRef(null)

  // console.log(JSON.stringify(locations.map(location => ({
  //   type: 'Feature',
  //   properties: {},
  //   geometry: {
  //     type: 'Point',
  //     coordinates: [location.lng, location.lat]
  //   }
  // }))))

  // useEffect(() => {
  // if (mapContainer && mapContainer.current) {
  //   const map = new mapboxgl.Map({
  //     accessToken: mapboxToken,
  //     container: mapContainer.current,
  //     style: 'mapbox://styles/mapbox/streets-v11',
  //     center: [-95.9389, 36.1410],
  //     zoom: 10
  //   })

  //   map.scrollZoom.disable()
  //   map.addControl(new mapboxgl.NavigationControl())
  //   map.addControl(new mapboxgl.GeolocateControl({ positionOptions: { enableHighAccuracy: true } }))
  //   locations.forEach(location => {
  //     new mapboxgl.Marker().setLngLat([location.lng, location.lat]).addTo(map)
  //   })

  // map.on('load', () => {
  //   map.addSource('locations', {
  //     type: 'geojson',
  //     data: {
  //       type: 'FeatureCollection',
  //       features: JSON.stringify(locations.map(location => ({
  //         type: 'Feature',
  //         properties: {},
  //         geometry: {
  //           type: 'Point',
  //           coordinates: [location.lng, location.lat]
  //         }
  //       })))
  //     }
  //   })

  //   console.log('locations', map.getSource('locations'))

  //   map.addLayer({
  //     id: 'locations',
  //     type: 'symbol',
  //     source: 'locations',
  //     layout: {
  //       'icon-image': 'rocket-15'
  //     }
  //   })
  // })

  // map.on('move', () => {
  //   const { lng, lat } = map.getCenter()
  //   setView({
  //     longitude: lng.toFixed(4),
  //     latitude: lat.toFixed(4),
  //     zoom: map.getZoom().toFixed(2)
  //   })
  // })

  //     return () => map.remove()
  //   }
  // }, [block])

  return (
    <section className={styles.section}>
      {/* <div ref={mapContainer} className={styles.mapContainer} /> */}
    </section>
  )
}
