import React from 'react'
import Image from 'gatsby-image/withIEPolyfill'

export const Img = ({ image, className }) => image ? image.childImageSharp && image.childImageSharp.fluid ? (
  <Image className={className} fluid={image.childImageSharp.fluid} />
) : image.publicURL ? (
  <img className={className} src={image.publicURL} />
) : (
  <img className={className} src={image} />
) : (
  null
)
