import React from 'react'

import { block, groupList, select, upload } from '../../fields'
import { id, ImgDiv, placeholder } from '../../utilities'

import styles from './images.module.scss'

const generateBlock = () => ({
  style: 'default',
  items: [generateItem()]
})

const generateItem = () => ({
  id: id(),
  image: placeholder(),
  disabled: false
})

export const ImagesSettings = images => block('Images', [
  select('Style', ['default', 'Cover', 'Contain', 'Cascade Left', 'Cascade Right']),
  groupList('Items', [
    upload(images)
  ], item => ({
    key: item.id,
    label: 'Image'
  }))
], generateBlock())

export const Images = ({ block: { items } }) => (
  <section className={styles.section}>
    {items.filter(item => !item.disabled).map(item => {
      return (
        <ImgDiv key={item.id} image={item.image} className={styles.image} />
      )
    })}
  </section>
)
