import camelCase from 'camelcase'
import { id } from '../utilities'

export const groupList = (
  label: string,
  fields: any[] = [],
  itemProps: any = item => ({
    key: item.id,
    label: item.name
  }),
  defaultItem: any = () => ({
    id: id(),
    name: 'New Item',
    disabled: false
  }),
  name: string = camelCase(label),
  description: string = '',
  rawJson: boolean = false
) => ({
  component: 'group-list',
  label,
  name: rawJson ? `rawJson.${name}` : name,
  description,
  fields,
  itemProps,
  defaultItem
})
