import React from 'react'

import { block, text } from '../../fields'
import { lipsum } from '../../utilities'

import styles from './slider.module.scss'

export const SliderSettings = () => block('Slider', [
  text('Title')
], {
  title: lipsum.generateWords(4)
})

export const Slider = ({ block }) => {
  return (
    <section className={styles.section}>
      {block._template}
    </section>
  )
}
