import React from 'react'

import { block, groupList, text, textarea, upload } from '../../fields'
import { id, Img, lipsum, placeholder } from '../../utilities'

import styles from './imageBar.module.scss'

const generateBlock = () => ({
  items: [generateItem(), generateItem(), generateItem()]
})

const generateItem = () => ({
  id: id(),
  label: lipsum.generateWords(3),
  alt: lipsum.generateWords(5),
  imageDescription: lipsum.generateSentences(3),
  image: placeholder(),
  url: '',
  disabled: false
})

export const ImageBarSettings = images => block('Image Bar', [
  groupList('Items', [
    text('Label'),
    upload(images),
    text('Image Alt Tag', 'alt'),
    textarea('Image Description'),
    text('URL')
  ], item => ({
    key: item.id,
    label: item.label || 'Untitled'
  }))
], generateBlock())

export const ImageBar = ({ block: { items } }) => {
  return (
    <section className={styles.section}>
      {items.map((item, i) => {
        return item.url ? (
          <a className={styles.link} key={i} href={item.url || ''}>
            {item.image ? <Img className={styles.image} image={item.image} /> : <p>{item.label}</p>}
          </a>
        ) : (
          item.image ? <Img key={i} className={styles.image} image={item.image} /> : <p>{item.label}</p>
        )
      })}
    </section>
  )
}
