import camelCase from 'camelcase'

export const textarea = (
  label: string,
  name: string = camelCase(label),
  description: string = '',
  required: boolean = false,
  rawJson: boolean = false
) => ({
  component: 'textarea',
  label,
  name: rawJson ? `rawJson.${name}` : name,
  description,
  required
})
