import React from 'react'

import { block, text, textarea, upload, groupList, toggle } from '../../fields'
import { ImgDiv, lipsum, placeholder, id } from '../../utilities'

import styles from './testimonial.module.scss'

export const TestimonialSettings = images => block('Testimonial', [
  textarea('Quote'),
  textarea('Author'),
  text('Button Label'),
  groupList('Items', [
    upload(images),
    toggle('Disabled')
  ])
], {
  quote: lipsum.generateSentences(1),
  author: lipsum.generateWords(2) + '\n' + lipsum.generateWords(5),
  buttonLabel: lipsum.generateWords(3),
  items: [{
    id: id(),
    image: placeholder(),
    disabled: false
  }]
})

export const Testimonial = ({ block }) => {
  return (
    <section className={styles.section}>
      <section className={styles.left}>
        {block.quote && <p>{block.quote}</p>}
        {block.author && <p>{block.author}</p>}
        {block.buttonLabel && <p>{block.buttonLabel}</p>}
      </section>
      <section className={styles.right}>
        {block.items.filter(item => !item.disabled).map(item => {
          return (
            <ImgDiv key={item.id} className={styles.image} image={item.image} />
          )
        })}
      </section>
    </section>
  )
}
