import camelCase from 'camelcase'

export const toggle = (
  label: string,
  name: string = camelCase(label),
  description: string = '',
  rawJson: boolean = false
) => ({
  component: 'toggle',
  label,
  parse: value => value || false,
  name: rawJson ? `rawJson.${name}` : name,
  description
})
