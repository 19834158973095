import React, { useState } from 'react'

import { block, text, groupList, textarea, toggle } from '../../fields'
import { id, lipsum } from '../../utilities'

import styles from './knowledgeBase.module.scss'

const generateItem = () => ({
  id: id(),
  title: lipsum.generateWords(4),
  text: lipsum.generateSentences(4),
  disabled: false
})

const generateCategory = () => ({
  id: id(),
  title: lipsum.generateWords(4),
  items: [generateItem(), generateItem(), generateItem()],
  disabled: false
})

const generateBlock = () => ({
  title: lipsum.generateWords(2),
  categories: [generateCategory(), generateCategory(), generateCategory()]
})

export const KnowledgeBaseSettings = () => block('Knowledge Base', [
  text('Title'),
  groupList('Categories', [
    text('Title'),
    groupList('Items', [
      text('Title'),
      textarea('Text'),
      toggle('Disabled')
    ], item => ({
      key: item.id,
      label: item.title
    }), generateItem()),
    toggle('Disabled')
  ], item => ({
    key: item.id,
    label: item.title
  }), generateCategory())
], generateBlock())

export const KnowledgeBase = ({ block: { categories } }) => {
  const [activeCategory, setActiveCategory] = useState(0)

  return (
    <section className={styles.section}>
      <div className={styles.categories}>
        {categories.filter(category => !category.disabled).map((category, i) => {
          const handleCategoryClick = () => {
            setActiveCategory(i)
          }
          return (
            <div key={category.id} className={styles.category + `${activeCategory === i ? ` ${styles.active}` : ''}`}>
              <p onClick={handleCategoryClick}>{category.title}</p>
            </div>
          )
        })}
      </div>
      <div className={styles.items}>
        {categories.filter(category => !category.disabled).map((category, i) => {
          return (
            <div key={category.id} className={styles.item + `${activeCategory === i ? ` ${styles.active}` : ''}`}>
              {category.items.filter(item => !item.disabled).map(item => {
                return (
                  <div key={item.id}>
                    <p>
                      <strong>{item.title}</strong>
                    </p>
                    <p>{item.text}</p>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </section>
  )
}
