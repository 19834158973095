import camelCase from 'camelcase'

export const number = (
  label: string,
  name: string = camelCase(label),
  description: string = '',
  step: number = 1,
  required: boolean = false,
  rawJson: boolean = false
) => ({
  component: 'number',
  label,
  name: rawJson ? `rawJson.${name}` : name,
  description,
  step,
  required
})
