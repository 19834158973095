const placeholders = [
  '../images/placeholders/placeholder-0.png',
  '../images/placeholders/placeholder-1.png',
  '../images/placeholders/placeholder-2.png',
  '../images/placeholders/placeholder-3.png',
  '../images/placeholders/placeholder-4.png',
  '../images/placeholders/placeholder-5.png',
  '../images/placeholders/placeholder-6.png',
  '../images/placeholders/placeholder-7.png',
  '../images/placeholders/placeholder-8.png',
  '../images/placeholders/placeholder-9.png'

]

export const placeholder = () => placeholders[Math.floor(Math.random() * placeholders.length)]
