import React from 'react'

import { block, text, groupList, upload, toggle, textarea } from '../../fields'
import { lipsum, id, placeholder } from '../../utilities'

import styles from './tabbedImages.module.scss'

const generateItem = () => ({
  id: id(),
  image: placeholder(),
  title: lipsum.generateWords(5),
  description: lipsum.generateParagraphs(1) + '\n\n' + lipsum.generateParagraphs(1),
  disabled: false
})

const generateCategory = () => ({
  id: id(),
  category: lipsum.generateWords(2),
  items: [generateItem(), generateItem()],
  disabled: false
})

export const TabbedImagesSettings = images => {
  return block('Tabbed Images', [
    groupList('Categories', [
      text('Category'),
      groupList('Items', [
        upload(images),
        text('Title'),
        textarea('Description'),
        toggle('Disabled')
      ], item => ({
        key: item.id,
        label: item.title
      })),
      toggle('Disabled')
    ], item => {
      return ({
        key: item.id,
        label: item.category
      })
    })
  ], {
    categories: [generateCategory(), generateCategory()]
  })
}

export const TabbedImages = ({ block }) => {
  console.log(block)
  return (
    <section className={styles.section}>{block._template}</section>
  )
}
