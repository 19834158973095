import camelCase from 'camelcase'

export const select = (
  label: string,
  options: string[] = [],
  name: string = camelCase(label),
  description: string = '',
  rawJson: boolean = false
) => ({
  component: 'select',
  label,
  name: rawJson ? `rawJson.${name}` : name,
  description,
  options
})
